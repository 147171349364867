<template>
  <!-- 轮播图 -->
  <div class="garousel">
    <div v-for="(item, index) in content" :key="index" class="garousel">
      <a class="Carousel_img"><img :src="item.img" alt="" /></a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          img: require("@/assets/imgs/aboutus/banner.png"),
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.garousel,
.Carousel_img,
.Carousel_img > 
img {
   min-width: 1225px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
    min-height: 755px;
}

.Carousel_img {
  display: inline-block;
}

</style>

