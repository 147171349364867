<template>
  <div class="History">
    <img class="ims" src="@/assets/imgs/aboutus/pment.png" alt="" />
    <div class="History_box">
      <div class="History_content">
        <div class="content_top">
          <p>昆仑虚发展史</p>
          <p>DEVELOPMENT</p>
        </div>
        <div class="content_bottom">
          <div class="top">
            <div class="iaml" v-for="(item, index) in content" :key="index">
              <p>{{ item.text }}</p>
              <p>{{ item.textl }}</p>
            </div>
          </div>
          <img class="imls" src="@/assets/imgs/aboutus/develo.png" alt="" />
          <div class="bottom">
            <div class="iamt" v-for="(itea, index) in cont" :key="index">
              <p>{{ itea.textl }}</p>
              <p>{{ itea.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: [
        {
          text: "公司成立并购3家互联网软件服务企业",
          textl: "2019年3月",
        },
        {
          text: "成功孵化电商品牌辣风芹、欧度等传统企业",
          textl: "2019年12月",
        },
        {
          text: "集团公司正式开始对外合作电商孵化、品牌孵化运营",
          textl: "2019年7月",
        },
      ],
      cont: [
        {
          text: "首批合作企业及电商项目开始获利，品牌塑造进入大众眼球",
          textl: "2020年5月",
        },
        {
          text: "公司将完善发展项目及结构，不断扩充合作商家，帮助更多中小企业开拓新市场！",
          textl: "2021年及未来",
        },
        {
          text: "集团开始大规模扩充团队以及各个市级县代理以及合作",
          textl: "2020年12月",
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.History,
.ims {
  width: 100%;
  height: 100vh;
  background: turquoise;
  margin: 0 auto;
  position: relative;
  .History_box {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 100;
    width: 100%;
    height: 100%;
    .History_content {
      max-width: 1200px;
      height: 800px;
      margin: 0 auto;
      margin-top: 100px;
      .content_top {
        text-align: center;
        p:nth-child(1) {
          font-size: 30px;
          font-weight: 300;
          letter-spacing: 3px;
          color: #ffffff;
          margin-bottom: 20px;
        }
        p:nth-child(2) {
          letter-spacing: 2px;
          font-size: 20px;
          font-weight: 300;
          color: #ffffff;
        }
      }
      .content_bottom {
        .top {
          max-width: 920px;
          margin-top: 100px;
          margin-bottom: 20px;
          margin-left: 235px;
          .iaml {
            width: 243px;
            margin: 0 auto;
            text-align: center;
            p:nth-child(1) {
              font-size: 17px;
              font-weight: 400;
              color: #ffffff;
              line-height: 24px;
            }
            p:nth-child(2) {
              margin-top: 15px;
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
            }
          }
          .iaml:nth-child(1) {
            float: left;
          }
          .iaml:nth-child(2) {
            float: right;
          }
        }
        .imls {
          width: 100%;
        }
        .bottom {
          max-width: 920px;
          margin-top: 20px;
          margin-left: 62px;
          .iamt {
            width: 243px;
            margin: 0 auto;
            text-align: center;
            p:nth-child(1) {
              margin-bottom: 15px;
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
            }
            p:nth-child(2) {
              font-size: 17px;
              font-weight: 400;
              color: #ffffff;
              line-height: 24px;
            }
          }
          .iamt:nth-child(1) {
            float: left;
          }
          .iamt:nth-child(2) {
            float: right;
          }
        }
      }
    }
  }
}
</style>
