<template>
  <div>
    <Book />
    <Brief />
    <Strategy />
    <History /> 
  </div>
</template>
<script>
import Book from "@/components/pages/aboutus/Book.vue";
import Brief from "@/components/pages/aboutus/Brief.vue";
import Strategy from "@/components/pages/aboutus/Strategy.vue";
import History from "@/components/pages/aboutus/History.vue";
export default {
  components: {
    Book,
    Brief,
    Strategy,
    History,
  },
  data() {
    return {};
  },
};
</script>
<style scoped></style>
