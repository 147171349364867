<template>
  <div class="strategy">
    <div class="strategy_box">
      <div class="strategy_top">
        <p>昆仑虚战略</p>
        <p>STRATEGY</p>
      </div>
      <div class="strategy_bottom">
        <div class="content" v-for="(item, index) in content" :key="index">
          <div class="content_top">
            <p class="top_text">{{item.data}}</p>
          </div>
          <div class="content_bottom">
            <p class="bottom_text">
              {{item.text}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: [
        {
          data: "公司目标",
          text: "为品牌发展贡献自己的专业力量，助力设计行业发展步伐",
        },
        {
          data: "公司使命",
          text: "透彻剖析客户需求，专业服务于每位客户",
        },
        {
          data: "公司文化",
          text: "不断超越，追求完美诚信为本，创新为魂居安思危，自强不息",
        },
        {
          data: "公司愿景",
          text: "成为客户认可、喜爱与尊重的营销服务提供商",
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.strategy {
  width: 100%;
  background: #fffdfd;
  margin-top: 200px;
  margin-bottom: 200px;
  overflow: hidden;
  .strategy_box {
    max-width: 1195px;
    margin: 0 auto;
    .strategy_top {
      text-align: center;
      p:nth-child(1) {
        font-size: 30px;
        font-weight: 300;
        letter-spacing: 3px;
        color: #3c3c3c;
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        letter-spacing: 2px;
        font-size: 20px;
        font-weight: 300;
        color: #3c3c3c;
      }
    }
    .strategy_bottom {
      background: #fffdfd;
      margin-top: 120px;
      .content {
        width: 210px;
        .content_top {
          width: 85px;
          height: 85px;
          overflow: hidden;
          border-radius: 50%;
          background: #333333;
          margin: 0 auto;
          margin-bottom: 40px;
          .top_text {
            margin-left: 25px;
            margin-top: 20px;
            width: 44px;
            height: 44px;
            font-size: 17px;
            font-weight: bold;
            color: #ffffff;
            line-height: 24px;
          }
        }
        .content_bottom {
          .bottom_text {
            width: 210px;
            font-size: 18px;
            text-align: center;
            font-weight: unset;
            color: #666666;
            line-height: 24px;
          }
        }
      }
      .content:nth-child(1) {
        float: left;
      }
      .content:nth-child(2) {
        margin-left: 120px;
        margin-right: 120px;
        float: left;
      }
      .content:nth-child(3) {
        float: right;
      }
      .content:nth-child(4) {
        float: left;
      }
    }
  }
}
</style>
