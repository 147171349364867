<template>
  <div class="brief">
    <div class="brief_box">
      <div class="brief_top">
        <p>昆仑虚简介</p>
        <p>ABOUT ME</p>
      </div>
      <div class="brief_botton">
        <p class="p">
          四川昆仑虚科技发展有限公司，简称昆仑虚科技（证券代码814418），成立于2019年3月。公司于2020年6月完成技术研发并融资成功，于同年9月打造出完善的商户服务体系并购三家公司，建立了网红孵化、品牌孵化、电商孵化三合一的孵化体系，公司于同年十二月成功在天府联合股权交易中心双创企业板挂牌上市。
          <br />昆仑虚围绕企业品牌效应、产品竞争力、溢价能力、销售渠道、体系搭建等核心问题，为众多企业提供了全方位的产品爆款、背景打造、商业模式、电商渠道等孵化服务。
          <br />昆仑虚致力于通过综合性的产品和服务，助力企业向数字化转型，通过技术、前沿模式驱动商业革新，让商业运作更简单！
        </p>
        <div class="data_display">
          <div class="data_bo" v-for="(item, index) in content" :key="index">
            <p>{{item.data}}</p>
            <p>{{item.text}}</p>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: [
        {
          data: "1600+",
          text: "渠道代理商超1600家",
        },
        {
          data: "13000+",
          text: "超过13000次优质服务",
        },
         {
          data: "500+",
          text: "服务超500家中小型企业",
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.brief {
  width: 100%;
  overflow: hidden;
  background: #fffdfd;
  text-align: center;
  .brief_box {
    margin-top: 200px;
    .brief_top {
      p:nth-child(1) {
        font-size: 30px;
        font-weight: 300;
        letter-spacing: 3px;
        color: #3c3c3c;
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        letter-spacing: 2px;
        font-size: 20px;
        font-weight: 300;
        color: #3c3c3c;
      }
    }
    .brief_botton {
      max-width: 1195px;
      margin: 0 auto;
      .p {
        margin-top: 60px;
        margin-bottom: 100px;
        text-align: left;
        font-size: 18px;
        font-weight: 300;
        line-height: 40px;
        color: #3c3c3c;
      }
      .data_display {
        .data_bo {
          text-align: center;
          P:nth-child(1) {
            font-size: 55px;
            font-weight: bold;
            color: #4d4d4d;
            margin-bottom: 20px;
          }
          P:nth-child(2) {
            font-size: 17px;
            letter-spacing: 3px;
            font-weight: bold;
            color: #3c3c3c;
          }
        }
        .data_bo:nth-child(1) {
          float: left;
        }
        .data_bo:nth-child(2) {
          float: right;
        }
      }
    }
  }
}
</style>
